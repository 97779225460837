<template>
  <base-section id="marketing">
    <v-container>
      <v-row>
        <v-col
          cols="3"
          class="text-center hidden-sm-and-down"
        >
          <template v-for="(card, i) in cards">
            <div
              :key="`div-${i}`"
              class="d-flex pt-2 mb-8"
            >
              <v-btn
                :key="i"
                dark
                icon
                class="mr-1 mb-3"
                @click="toggleButton(card.title)"
              >
                <base-avatar
                  :key="i"
                  :color="card.isActive ? 'primary': 'grey lighten-3'"
                  :dark="card.isActive"
                  :icon="card.icon"
                  align="center"
                  size="72"
                  outlined
                />
              </v-btn>

              <div class="ml-6">
                <div
                  :key="`title-${i}`"
                  class="ml-6"
                >
                  <base-title
                    :title="card.title"
                    class="text-uppercase"
                    space="3"
                  />
                </div>
              </div>
            </div>

            <v-divider
              v-if="i + 1 !== cards.length"
              :key="`divider-${i}`"
              class="my-8"
            />
          </template>
        </v-col>

        <v-col
          cols="2"
          class="hidden-sm-and-down text-center"
        >
          <v-responsive
            height="calc(100% - 16px)"
            class="mt-2"
          >
            <v-divider vertical />
          </v-responsive>
        </v-col>

        <v-col
          v-show="selectedTitle === 'Scrum Teams'"
          cols="12"
          md="7"
        >
          <base-subheading
            size="display-1"
            title="Creating Teams and Communities"
            space="0"
          />

          <base-title
            class="primary--text"
            title=""
            tag="div"
          />

          <base-body>
            A high-performing scrum team delivers quality products, but also
            helps its members grow and learn from each other to become even
            stronger. As an individual or as a group, there is nothing more
            satisfying in work than coming together with the common goal of
            building successful outcomes for a client. Members with diverse
            backgrounds and socio-economic status come together, work together,
            inspire each other, teach each other and become a productive
            micro-community adding value to the greater society overall.
          </base-body>

          <base-btn
            :to="{ name: 'Discover' }"
            class="mb-12"
          >
            Discover More
          </base-btn>

          <v-row>
            <v-col
              v-for="(text, i) in marketing"
              :key="i"
              cols="12"
              md="6"
            >
              <base-list-item :text="text" />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-show="selectedTitle === 'Communities'"
          cols="12"
          md="7"
        >
          <base-subheading
            size="display-1"
            title="Communities"
            space="0"
          />

          <base-title
            class="primary--text"
            title=""
            tag="div"
          />

          <base-body>
            ScrumOnDemand offers Talent from varying  Communities.  You have the option to choose the one that fits your goals the most.
          </base-body>

          <v-row class="text-center">
            <v-col
              v-for="item of tally"
              :key="item.Key"
              cols="6"
              md="3"
            >
              <base-circular-progress
                :title="item.Key"
                :value="item.Value"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMarketing',

    data: () => ({
      selectedTitle: 'Scrum Teams',
      cards: [
        {
          title: 'Scrum Teams',
          icon: 'mdi-account-group-outline',
          isActive: true,
        },
        {
          title: 'Communities',
          icon: 'mdi-select-group',
          isActive: false,
        },
      ],
      marketing: [
        'Veterans looking to leverage their skills into a career in product development',
        'Experienced Scrum teams mentoring junior members of ScrumOnDemand',
        'Tech-savvy kids from around the world banding together to create a cyber-cool product',
        'Scrum team experts hosting teaching events',
        'Tech people looking for a side hustle',
        'Clients seeing the possibilities of engaging global work communities to bring their corporate value to the next level',
      ],
      values: [
        [75, 'Prototyping'],
        [50, 'UI/UX Design'],
        [100, 'Development'],
        [65, 'Marketing'],
      ],
    }),

    computed: {
      tally () {
        return this.$store.getters['community/tally']
      },
    },

    mounted () {
      this.fetCommunityTally()
    },

    methods: {
      fetCommunityTally () {
        return this.$store.dispatch('community/getTally')
      },
      toggleButton (title) {
        for (let i = 0; i < this.cards.length; i++) {
          this.cards[i].isActive = false

          if (this.cards[i].title === title) {
            this.cards[i].isActive = true
            this.selectedTitle = title
          }
        }
      },
    },
  }
</script>

<style lang="sass">
.button-cursor
  cursor: pointer
</style>
